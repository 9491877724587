import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/uireact/uireact/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { UiLineSeparator } from '@uireact/separator';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p><a parentName="p" {...{
        "href": "https://github.com/inavac182/uireact/actions/workflows/pipeline.yml"
      }}><img alt="Build and Deploy" src="https://github.com/inavac182/uireact/actions/workflows/pipeline.yml/badge.svg" /></a>{`
`}<img alt="React" src="https://img.shields.io/badge/React-20232A?style=for-the-badge&logo=react&logoColor=61DAFB" />{`
`}<img alt="Styled Components" src="https://img.shields.io/badge/styled--components-DB7093?style=for-the-badge&logo=styled-components&logoColor=white" />{`
`}<img alt="Lerna" src="https://img.shields.io/badge/Lerna-3E3E3E?style=for-the-badge&logo=lerna&logoColor=white" />{`
`}<img alt="TS" src="https://img.shields.io/badge/TypeScript-007ACC?style=for-the-badge&logo=typescript&logoColor=white" /></p>
    <div style={{
      "display": "flex",
      "alignItems": "baseline",
      "gap": "10px"
    }}>
  <h1 style={{
        "fontFamily": "Sen",
        "display": "inline"
      }}>
    <span style={{
          "color": "#F08B31"
        }}>@</span>
    <span style={{
          "color": "#7CE91B"
        }}>U</span>
    <span style={{
          "color": "#1BE9D9"
        }}>i</span>
    <span style={{
          "color": "#E91BE0"
        }}>R</span>
    <span style={{
          "color": "#EDF031"
        }}>e</span>
    <span style={{
          "color": "#31F051"
        }}>a</span>
    <span style={{
          "color": "#F0314E"
        }}>c</span>
    <span style={{
          "color": "#31F096"
        }}>t</span>
    .io
  </h1>
  <img src="/public/sunglasses_cat.gif" alt="icon" width="30px" />
    </div>
    <UiLineSeparator mdxType="UiLineSeparator" />
    <span style={{
      "fontSize": "14px"
    }} data-nosnippet>
  We are too busy <span style={{
        "fontSize": "10px"
      }}>(or too lazy? 😅)</span> to have a shiny ✨ page, we hope this help you
  to start that amazing idea 💡 you've been holding off for a long time. So, bring that unicorn 🦄 or brick 🧱 alive!!!
    </span>
    <br />
    <UiLineSeparator mdxType="UiLineSeparator" />
    <h2 {...{
      "id": "what-"
    }}>{`What? 😲`}</h2>
    <p>{`@UiReact is a React library of Themed UI components that are ready to be used to speed up react web development. The themes allows for a lot of customization while trying to keep it simple for theming.`}</p>
    <h2 {...{
      "id": "why-"
    }}>{`Why? 🤓`}</h2>
    <p>{`This project started due to personal projects that we worked and everytime we needed to create a set of UI components, theme management, boilerplate code, utils, etc... All while we tried to keep things moving fast! ⚡️ Other UI libraries
were not very good for our use cases, and were not as complete as what we needed, so we just started our own with everything we use.`}</p>
    <h2 {...{
      "id": "where-to-start-️"
    }}>{`Where to start? ✍️`}</h2>
    <p>{`The main package is `}<inlineCode parentName="p">{`@uireact/foundation`}</inlineCode>{` were all components uses to retrieve the theme context and afew other tools.
We have a `}<a parentName="p" {...{
        "href": "./getting-started"
      }}>{`getting started doc`}</a>{` that will help you to start with this library, it also includes a few examples.`}</p>
    <h2 {...{
      "id": "tech-stack-️"
    }}>{`Tech Stack ⚙️`}</h2>
    <p>{`If you want to understand how this library works the tech stack that we use is:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://lerna.js.org/"
        }}>{`Lerna`}</a>{` `}<span style={{
          "fontSize": "14px"
        }}>{`For monorepo management 🫶`}</span></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://styled-components.com/"
        }}>{`Styled components`}</a>{` `}<span style={{
          "fontSize": "14px"
        }}>{`For the components styling (css) 💅🏿`}</span></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.typescriptlang.org/"
        }}>{`Typescript`}</a>{` `}<span style={{
          "fontSize": "14px"
        }}>{`To prevent 🐛 (never been more misktaken 🫠) `}</span></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://react.dev/"
        }}>{`React JS`}</a>{` `}<span style={{
          "fontSize": "14px"
        }}>{`For the win 🥷 `}</span></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://nodejs.org/"
        }}>{`Node JS`}</a>{` `}<span style={{
          "fontSize": "14px"
        }}>{`To rule them all 🦄`}</span></li>
    </ul>
    <iframe src="https://giphy.com/embed/Dh5q0sShxgp13DwrvG" width="300" height="200" frameBorder="0" className="giphy-embed" allowFullScreen></iframe>
    <h2 {...{
      "id": "contributions-"
    }}>{`Contributions 🤓`}</h2>
    <p>{`We are fully open to contributions, Look at these 2 pages with info about working with on this library:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://uireact.io/contributing"
        }}>{`Contributing`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://uireact.io/dev"
        }}>{`Development`}</a></li>
    </ul>
    <h2 {...{
      "id": "contact-️"
    }}>{`Contact ☎️`}</h2>
    <p>{`If you want to reach out to us these are our public social networks:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.linkedin.com/in/inavac/"
        }}>{`Felipe Nava`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://twitter.com/FelipeNava92"
        }}>{`Felipe's Twitter`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/inavac182/"
        }}>{`Felipe's gh`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.linkedin.com/in/sergio-audel-ortiz-gutierrez-1698b158/"
        }}>{`Sergio Ortiz`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/saudelog"
        }}>{`Sergio's gh`}</a></li>
    </ul>
    <h2 {...{
      "id": "just-have-fun-"
    }}>{`Just have fun 🫶`}</h2>
    <p>{`Remember that whatever you do, have fun while doing it 🤗`}</p>
    <p>{`Now, A photo from our hometown 🤩:`}</p>
    <p><img alt="Volcano" src={require("../public/colima.jpg")} /></p>
    <iframe src="https://giphy.com/embed/unxCGmTuBvwo2djRLA" width="300" height="200" frameBorder="0" className="giphy-embed" allowFullScreen></iframe>
    <iframe src="https://giphy.com/embed/vzO0Vc8b2VBLi" width="300" height="200" frameBorder="0" className="giphy-embed" allowFullScreen></iframe>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      